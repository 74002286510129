<template>
  <bsc-part page-type="terminal"></bsc-part>
</template>
<script>
// import { getItemFromArrayByKey } from 'U'
// import store from '@/store'
import bscPart from '../main/index.vue'
export default {
  name: 'wlwterminal',
  components: {
    bscPart,
  },
  beforeRouteEnter(to, from, next) {
    // let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    // if(!toTab || from.name != 'terminalArchivesDetail') {
    //   store.commit('delKeepAlive', to.name)
    // }
    next()
  },
}
</script>